import getDistance from "./getDistance";

const localTypesByBestMatch = [
  "ceremonial",
  "county",
  "administrative",
  "city",
  "town",
  "village",
  "hamlet",
  "suburban area",
  "other settlement",
];

const sortByLocation = ({
  locationArr,
  listings,
  distanceRange,
  selectedCity,
  sortByDistance,
}) => {
  if (!locationArr || locationArr.length === 0) return [];
  if (!listings) return [];

  const bestMatch = locationArr.sort(
    (a, b) =>
      localTypesByBestMatch.indexOf(a.local_type?.toLowerCase()) -
      localTypesByBestMatch.indexOf(b.local_type?.toLowerCase())
  );

  const sourceLocation = {
    ...bestMatch[0],
    lat: bestMatch[0].latitude || locationArr[0]?.lat, // use lat/long if the geolocation api didnt return results and we set the locationArr to be the found cities, search for `const foundCities = cityFilter(activities);`
    long: bestMatch[0].longitude || locationArr[0]?.long,
  };

  const activitiesWithinRange = [];
  listings.forEach((listing) => {
    const { geoLocations = [] } = listing;

    // if they selected a city same as listing's city
    if (listing.city?.includes(selectedCity)) {
      const distance = 0;
      activitiesWithinRange.push({ ...listing, distance });
    } else {
      const distancesByClosestFirst = geoLocations
        .map((loc) => getDistance(sourceLocation, loc))
        .filter((distance) => distance <= distanceRange)
        .sort((a, b) => a.distance - b.distance);

      if (distancesByClosestFirst.length > 0) {
        activitiesWithinRange.push({
          ...listing,
          distance: distancesByClosestFirst[0],
        });
      }
    }
  });

  return sortByDistance
    ? activitiesWithinRange.sort((a, b) => a.distance - b.distance)
    : activitiesWithinRange;
};

export default sortByLocation;
